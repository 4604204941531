const EMPTY_CACHE = { pending: {}, data: {} };

export class AppStore {
  constructor(store = EMPTY_CACHE) {
    this._store = store;
  }

  async fetch(key, onCacheMiss, ignoreCache = false) {
    if (ignoreCache) {
      console.log('Cache Ignored: ', key);
      return await onCacheMiss();
    }

    if (this._store.data[key]) {
      console.log('Cache Hit: ', key);
      return await new Promise(resolve => resolve(this._store.data[key]));
    }

    if (this._store.pending[key]) {
      console.log('Cache pending: ', key);
      return await this._store.pending[key];
    }

    console.log('Cache Miss: ', key);
    const data = await onCacheMiss();
    this._store.data[key] = data;
    delete this._store.pending[key];
    return data;
  }

  clear() {
    this._store = EMPTY_CACHE;
  }
}
